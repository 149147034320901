import React from "react";
import PropTypes from "prop-types";
import GoogleMapReact from "google-map-react";

import { graphql } from "gatsby";
import { FaMapMarkerAlt } from "react-icons/fa";
import Layout from "../components/layout";
import SEO from "../components/seo";

function IndexPage({ data }) {
  const { frontmatter, html } = data.markdownRemark;
  return (
    <Layout fixedHeader={frontmatter.image ? true : false}>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title={frontmatter.title}
      />
      <div className="w-full h-screen" style={{ maxHeight: "600px" }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyC-pek62fWn7mFLTVktr2LKWEX6aEJLdWg" }}
          defaultCenter={[56.216429, -3.4596157]}
          defaultZoom={12}
        >
          <div className="marker" lat="56.216429" lng="-3.4596157">
            <FaMapMarkerAlt
              size={48}
              style={{ transform: "translateY(-100%)" }}
            />
          </div>
        </GoogleMapReact>
      </div>
      <section className="w-full py-12">
        <div className="container">
          <div className="md:flex">
            <div className="md:w-1/4">
              <h4>
                <span className="inline-block pb-2 mb-2 border-b-4 border-black ">
                  Our Address
                </span>
              </h4>
              <p className="mb-2">
                Balado Airfield
                <br />
                Kinross
                <br />
                KY13 0RF
                <br />
                Scotland
              </p>
              <p className="mb-2">
                <strong className="font-semibold">Grid reference:</strong>
                <br />
                56° 13’ 02” N , 003° 27’ 19” W
              </p>
              <p className="mb-2">
                <strong className="font-semibold">Email:</strong>
                <br />
                <a href="mailto:info@baladoairfield.co.uk">
                  info@baladoairfield.co.uk
                </a>
              </p>
              <p className="mb-2">
                <strong className="font-semibold">Phone:</strong>
                <br />
                07795 578072
              </p>
            </div>
            <div className="flex-1">
              <article className="max-w-3xl prose prose-xl">
                <div dangerouslySetInnerHTML={{ __html: html }} />
                <form
                  className="py-2"
                  name="contact"
                  method="POST"
                  data-netlify="true"
                >
                  <input type="hidden" name="form-name" value="contact" />

                  <input
                    required
                    type="text"
                    name="name"
                    placeholder="Your name"
                    className="focus:outline-none focus:shadow-outline appearance-none mb-4 bg-gray-300 p-2 pb w-full "
                  />
                  <input
                    required
                    type="text"
                    name="email"
                    placeholder="Your email"
                    className="focus:outline-none focus:shadow-outline appearance-none mb-4 bg-gray-300 p-2 pb w-full "
                  />
                  <input
                    type="text"
                    name="phone"
                    placeholder="Your phone"
                    className="focus:outline-none focus:shadow-outline appearance-none mb-4 bg-gray-300 p-2 pb w-full "
                  />
                  <textarea
                    required
                    name="message"
                    placeholder="Your message"
                    className="focus:outline-none focus:shadow-outline appearance-none mb-4 bg-gray-300 p-2 pb w-full "
                    rows="4"
                  />
                  <button className="font-impact  text-3xl text-white border-white border-2 py-2 px-5 text-lg inline-block bg-primary uppercase font-bold">
                    Submit
                  </button>
                </form>
              </article>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export const PageTemplate = ({ image, heading, html }) => (
  <>
    <section
      className="relative flex items-center justify-center w-full h-screen py-16 bg-black"
      style={{ maxHeight: "600px" }}
    >
      <img
        alt="Festivals"
        className="absolute inset-0 object-cover w-full h-full opacity-75"
        src={image}
      />
      <div className="container relative z-10 px-2 text-white">
        <div className="max-w-4xl">
          <h2 className="font-bold text-white title pattern-text">{heading}</h2>
        </div>
      </div>
    </section>
    <section className="w-full py-12">
      <div className="container">
        <article
          className="max-w-3xl prose prose-xl"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </div>
    </section>
  </>
);

PageTemplate.propTypes = {
  image: PropTypes.string,
  heading: PropTypes.string,
  html: PropTypes.string,
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
      html: PropTypes.string,
    }),
  }),
};

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        image
        intro
      }
      html
    }
  }
`;

export default IndexPage;
